import React, { useState } from 'react';
import axios from 'axios';

const CreateUserForm = () => {
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    admin: false,
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser({
      ...newUser,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create_user`,
        {
          ...newUser,
          username: newUser.email, // Set username to be the same as email
        }
      );
      if (response.status === 201) {
        setMessage("User created successfully!");
        setNewUser({
          email: "",
          password: "",
          admin: false,
        });
      }
    } catch (error) {
      setMessage(
        error.response?.data?.message || "Error occurred while creating user"
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md   mq1280:w-[850px]"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300">
            Password:
          </label>
          <input
            type="password"
            name="password"
            value={newUser.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="admin"
              checked={newUser.admin}
              onChange={handleChange}
              className="form-checkbox"
            />
            <span className="ml-2 text-gray-700 dark:text-gray-300">
              Admin?
            </span>
          </label>
        </div>

        <button
          type="submit"
          className="px-4 py-2 text-white bg-blue-500 rounded-md"
        >
          Create User
        </button>
      </form>

      {message && (
        <div className="mt-4 text-green-600 dark:text-green-400">
          {message}
        </div>
      )}
    </>
  );
};

export default CreateUserForm;
