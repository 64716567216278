import React, { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "./themeContext";
import { AuthProvider, useAuth } from "./AuthContext"; // Import AuthProvider and useAuth
import Evaulations from "./pages/Evaulations_Page/Evaulation_Page";
import Clients from "./pages/Client_Page/Clients";
import PatientsInterviewPage from "./pages/PatientsInterviewPage";
import New_Evaulations from "./pages/New_Evaulations_Page/New_Evaulation_Page";
import ProfileEvaulations from "./pages/InterviewList_Page/ProfileEvaulations";
import SectionEvaulations from "./pages/Section_List_Page/SectionEvaulations";

import AddInterview from "./pages/Add_Interview_Page/AddInterview";
import Interview_info_page from "./pages/Interview_Info_Page/Interview_info_page";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard"; // Import your Admin Dashboard
import Create_Evaulations from "./pages/Create_Evaulations/Create_Evaulations";

function ProtectedRoute({ element }) {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? element : <Navigate to="/login" />;
}

function AdminRoute({ element }) {
  const { isLoggedIn, isAdmin, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (isAdmin === 'admin') {
    return element;
  }

  if (isAdmin === 'owner') {
    return <Navigate to="/owner" />;
  }

  return <Navigate to="/unauthorized" />;
}

function AppContent() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/interviews":
        title = "";
        metaDescription = "";
        break;
      case "/clients":
        title = "";
        metaDescription = "";
        break;
      case "/patients-interview-page":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* Protected Routes */}
      <Route path="/" element={<ProtectedRoute element={<Evaulations />} />} />
      <Route path="/create" element={<ProtectedRoute element={<Create_Evaulations />} />} />

      <Route path="/new-evaluations" element={<ProtectedRoute element={<New_Evaulations />} />} />
      <Route path="/new-evaluations/:id" element={<ProtectedRoute element={<New_Evaulations />} />} />

      <Route path="/evaluation/section/:id" element={<ProtectedRoute element={<SectionEvaulations />} />} />
      <Route path="/evaluation/:id" element={<ProtectedRoute element={<ProfileEvaulations />} />} />

      <Route path="/addinterview/:id" element={<ProtectedRoute element={<AddInterview />} />} />
      <Route path="/interview/:id/:interviewid" element={<ProtectedRoute element={<Interview_info_page />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/clients" element={<ProtectedRoute element={<Clients />} />} />
      <Route path="/patients-interview-page" element={<ProtectedRoute element={<PatientsInterviewPage />} />} />
      
      {/* Admin Route */}
      <Route path="/admin-dashboard" element={<AdminRoute element={<AdminDashboard />} />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
