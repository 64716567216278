import React from "react";
import axios from "axios";
import { useTheme } from "../../themeContext"; // Import the custom hook
import { useAuth } from "../../AuthContext"; // Import the useAuth hook
import SideMenu from "../../components/SideMenu/SideMenu";
import TopBar from "../../components/TopBar/TopBar";
import CreateUserForm from './CreateUserForm';
import AdminTable from "./AdminTable";

const AdminDashboard = () => {
  const { theme } = useTheme(); // Get the current theme
  const { username } = useAuth(); // Get the username from the auth context

  return (
    <div
      className={`w-full min-h-screen overflow-x-hidden overflow-y-auto leading-normal tracking-normal text-left text-sm text-gray-700 font-sans  ${
        theme === "dark" ? "bg-[#3A3838] text-white" : "bg-white text-black"
      }`}
    >
      <TopBar title={"Admin Dashboard"} backUrl={`/`} />
      <SideMenu />

      <div className="absolute top-[209px] left-[382px]  mq1280:w-[850px]">
        <div>
          <h1>Admin Dashboard</h1>
          <p>Welcome, Admin! You have full access to this dashboard.</p>

          <h2 className="mt-8 mb-4 text-lg">Create New User</h2>
          <div className="mb-8">
            <CreateUserForm />
          </div>
          <AdminTable />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
