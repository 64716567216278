import React, { useState, useEffect } from "react";
import { useTheme } from "../../themeContext";
import { useAuth } from "../../AuthContext";
import TopBar from "../../components/TopBar/TopBar";
import SideMenu from "../../components/SideMenu/SideMenu";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaCheckCircle, FaCircle } from "react-icons/fa";

const AddInterview = () => {
  const { theme } = useTheme();
  const { username } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [interviewType, setInterviewType] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewerName, setInterviewerName] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [personDetails, setPersonDetails] = useState({});
  const [sections, setSections] = useState([]); // Add state for sections
  const [selectedSections, setSelectedSections] = useState([]); // Replace selectedSection state
  const [isUploading, setIsUploading] = useState(false);
  const [existingInterviews, setExistingInterviews] = useState([]);
  const [audioSource, setAudioSource] = useState('file');
  const [audioUrl, setAudioUrl] = useState('');

  useEffect(() => {
    if (id && username) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/people/${id}`, {
          params: {
            user_id: username,
          },
        })
        .then((response) => {
          setPersonDetails(response.data);
          setSections(response.data.sections); // Set sections from the API response
        })
        .catch((error) => {
          console.error("There was an error fetching the person's details!", error);
        });

      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/people/${id}/interviews`, {
          params: {
            user_id: username,
          },
        })
        .then((response) => {
          setExistingInterviews(response.data);
        })
        .catch((error) => {
          console.error("Error fetching existing interviews:", error);
        });
    }
  }, [id, username]);

  const handleFileChange = (event) => {
    setAudioFile(event.target.files[0]);
  };

  const handleSectionToggle = (sectionId) => {
    setSelectedSections(prevSelected => 
      prevSelected.includes(sectionId)
        ? prevSelected.filter(id => id !== sectionId)
        : [...prevSelected, sectionId]
    );
  };

  const handleNextClick = () => {
    setIsUploading(true);
    const interviewData = new FormData();
    interviewData.append("interview_type", interviewType);
    interviewData.append("interview_date", interviewDate);
    interviewData.append("interviewer_name", interviewerName);
    interviewData.append("person_id", id);
    interviewData.append("user_id", username);
    
    selectedSections.forEach(sectionId => {
      interviewData.append('section_ids[]', sectionId);
    });

    if (audioFile) {
      interviewData.append("audio_file", audioFile);
    } else if (audioUrl) {
      interviewData.append("audio_url", audioUrl);
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/interviews`, interviewData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const interviewId = response.data.interview_id;
        console.log(response.data);
        setIsUploading(false);
        navigate(`/interview/${id}/${interviewId}`);
      })
      .catch((error) => {
        console.error("There was an error uploading the interview!", error);
        setIsUploading(false);
      });
  };

  const isFormComplete = () => {
    return interviewType && interviewDate && interviewerName && 
           selectedSections.length > 0 && (audioFile || audioUrl);
  };

  return (
    <div
      className={`w-full min-h-screen relative overflow-hidden text-left text-sm font-poppins ${
        theme === "dark" ? "bg-[#3A3838] text-white" : "text-gray-800"
      }`}
    >
      <TopBar title={"Add Interview"} backUrl={`/evaluation/${id}`} />
      <SideMenu />

      <div className="absolute top-[144px] left-[351px] w-[calc(100%-351px)] px-8 py-6 flex flex-col items-start justify-start overflow-y-auto max-h-[calc(100vh-144px)]">
        <h2 className="text-2xl font-semibold mb-6">Add Interview</h2>

        <div className="w-full max-w-xl space-y-6">
          <div>
            <label className="block mb-2 font-medium">Type of Interview</label>
            <select
              value={interviewType}
              onChange={(e) => setInterviewType(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <option value="">Select Type</option>
              <option value="in_person">In Person</option>
              <option value="online">Online</option>
              <option value="on_call">On Call</option>
            </select>
          </div>

          <div>
            <label className="block mb-2 font-medium">Date of Interview</label>
            <input
              type="date"
              value={interviewDate}
              onChange={(e) => setInterviewDate(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
          </div>

          <div>
            <label className="block mb-2 font-medium">Interviewee Name</label>
            <select
              value={interviewerName}
              onChange={(e) => setInterviewerName(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <option value="">Select Interviewee</option>
              {personDetails.evaluated_parent && (
                <option value={`${personDetails.evaluated_parent.first_name} ${personDetails.evaluated_parent.last_name}`}>
                  Evaluated Parent: {personDetails.evaluated_parent.first_name} {personDetails.evaluated_parent.last_name}
                </option>
              )}
              {personDetails.children &&
                personDetails.children.map((child, index) => (
                  <option key={index} value={`${child.first_name} ${child.last_name}`}>
                    Child: {child.first_name} {child.last_name}
                  </option>
                ))}
              {personDetails.child_collateral_contacts &&
                personDetails.child_collateral_contacts.map((childCollateral, index) => (
                  <option key={index} value={`${childCollateral.first_name} ${childCollateral.last_name}`}>
                    Child Collateral: {childCollateral.first_name} {childCollateral.last_name}
                  </option>
                ))}
              {personDetails.parent_collateral_contacts &&
                personDetails.parent_collateral_contacts.map((parentCollateral, index) => (
                  <option key={index} value={`${parentCollateral.first_name} ${parentCollateral.last_name}`}>
                    Parent Collateral: {parentCollateral.first_name} {parentCollateral.last_name}
                  </option>
                ))}
            </select>
          </div>

          {/* Section Selection (using buttons) */}
          <div>
            <label className="block mb-2 font-medium">Sections</label>
            <div className="flex flex-wrap gap-2">
              {sections.map((section) => (
                <button
                  key={section.id}
                  onClick={() => handleSectionToggle(section.id)}
                  className={`px-3 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    selectedSections.includes(section.id)
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {selectedSections.includes(section.id) ? (
                    <FaCheckCircle className="inline-block mr-1" />
                  ) : (
                    <FaCircle className="inline-block mr-1" />
                  )}
                  {section.section_name}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block mb-2 font-medium">Audio File</label>
            <div className="border-2 border-dashed border-gray-300 rounded p-4 text-center">
              <input
                type="file"
                accept="audio/*"
                onChange={handleFileChange}
                className="hidden"
                id="audio-file-input"
              />
              <label htmlFor="audio-file-input" className="cursor-pointer text-gray-500">
                <div className="flex flex-col items-center justify-center">
                  {audioFile ? (
                    <FaCheckCircle className="text-green-500 text-2xl mb-2" />
                  ) : (
                    <span className="text-lg font-medium">Upload Audio File</span>
                  )}
                  <span className="mt-2 text-sm text-gray-400">
                    Attach file. File size of your documents should not exceed 10MB.
                  </span>
                </div>
              </label>
            </div>
            {audioFile && (
              <div className="mt-2 text-gray-500">
                {audioFile.name}
              </div>
            )}
          </div>

          <div>
            <label className="block mb-2 font-medium">Existing Audio Files</label>
            <select
              onChange={(e) => {
                const selected = existingInterviews.find(i => i.id === parseInt(e.target.value));
                if (selected) {
                  setAudioFile(null);
                  setAudioUrl(selected.audio_file);
                } else {
                  setAudioUrl('');
                }
              }}
              className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <option value="">Select an existing audio file</option>
              {existingInterviews
                .filter(interview => interview.audio_file)
                .map((interview) => (
                  <option key={interview.id} value={interview.id}>
                    {interview.audio_file.split('/').pop()}
                  </option>
              ))}
            </select>
          </div>

          <button
            className={`w-full py-3 rounded ${
              isFormComplete()
                ? "bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleNextClick}
            disabled={!isFormComplete()}
          >
            Next
          </button>

          {isUploading && (
            <div className="mt-4 text-blue-500">
              Uploading audio file to the database. Please wait...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddInterview;
