import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from "../../themeContext"; // Import the custom hook
import TopBar from "../../components/TopBar/TopBar";
import SideMenu from "../../components/SideMenu/SideMenu";
import NewEvaluationInputs from "./NewEvaluationInputs";
import Header from "./Header";
import axios from 'axios';

const New_Evaulations = () => {
  const { theme } = useTheme(); // Get the current theme
  const { id } = useParams(); // Get the person ID from the URL
  const [personData, setPersonData] = useState(null);

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/people2/${id}`)
        .then(response => {
          setPersonData(response.data);
        })
        .catch(error => {
          console.error("Error fetching person data:", error);
        });
    }
  }, [id]);

  return (
    <div className={`w-full h-screen relative overflow-x-hidden text-left text-sm font-poppins ${theme === "dark" ? "bg-[#3A3838] text-white" : "bg-white text-gray2"}`}>
      <TopBar title={"Add Evaluations"} backUrl={"/"}/>
      <SideMenu activePage="Evaluations" />
      <Header />

      <NewEvaluationInputs personData={personData} />
    </div>
  );
};

export default New_Evaulations;
