import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context for authentication
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("isAdmin") === "admin"
  );
  const [loading, setLoading] = useState(true);

  // Add timer ref
  const [timer, setTimer] = useState(null);

  const handleLogin = (status, username, adminStatus = "false") => {
    return new Promise((resolve) => {
      setLoggedIn(status);
      setUsername(username);
      setIsAdmin(adminStatus);
  
      localStorage.setItem("isLoggedIn", status.toString());
      localStorage.setItem("username", username);
      localStorage.setItem("isAdmin", adminStatus);
  
      resolve();
    });
  };
  

  const handleSignOut = () => {
    setLoggedIn(false);
    setUsername("");
    setIsAdmin(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");
    localStorage.removeItem("isAdmin");
  };

  // Add activity handler
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    // if (isLoggedIn) {
    //   const newTimer = setTimeout(() => {
    //     handleSignOut();
    //   }, 900000); // 15 minutes
    //   setTimer(newTimer);
    // }
  };

  // Add event listeners for user activity
  useEffect(() => {
    if (isLoggedIn) {
      // Set up event listeners
      const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
      
      const handleActivity = () => {
        resetTimer();
      };

      events.forEach(event => {
        document.addEventListener(event, handleActivity);
      });

      // Initial timer
      resetTimer();

      // Cleanup
      return () => {
        if (timer) clearTimeout(timer);
        events.forEach(event => {
          document.removeEventListener(event, handleActivity);
        });
      };
    }
  }, [isLoggedIn]); // Dependency on isLoggedIn

  useEffect(() => {
    // Load auth state from localStorage
    setLoggedIn(localStorage.getItem("isLoggedIn") === "true");
    setUsername(localStorage.getItem("username") || "");
    setIsAdmin(localStorage.getItem("isAdmin") || "");
    setLoading(false);
  }, []);

  const value = {
    isLoggedIn,
    username,
    isAdmin,
    handleLogin,
    handleSignOut,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
