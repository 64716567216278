import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import ClientListItem from "./ClientListItem";
import Pagination from "./Pagination";
import debounce from "lodash.debounce";
import { useTheme } from "../../themeContext"; // Import the custom hook
import { useAuth } from "../../AuthContext"; // Import the useAuth hook

const Table = () => {
  const { theme } = useTheme(); // Get the current theme
  const { username } = useAuth(); // Get the username from the auth context
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentClients, setCurrentClients] = useState([]);

  const fetchClients = useCallback(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/people`, {
      params: { user_id: username },
    })
    .then(response => {
      setClients(response.data);
      setFilteredClients(response.data);
      updateCurrentClients(response.data, 1);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
      setClients([]);
    });
  }, [username]);

  useEffect(() => {
    if (clients.length === 0 && username) {
      fetchClients();
    }
  }, [fetchClients, clients.length, username]);

  useEffect(() => {
    updateFilteredClients();
  }, [searchTerm, clients]);

  useEffect(() => {
    updateCurrentClients(filteredClients, currentPage);
  }, [filteredClients, currentPage, itemsPerPage]);

  const updateFilteredClients = () => {
    const filtered = clients.filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
    setCurrentPage(1);
    updateCurrentClients(filtered, 1);
  };

  const updateCurrentClients = (filtered, page) => {
    const indexOfLastClient = page * itemsPerPage;
    const indexOfFirstClient = indexOfLastClient - itemsPerPage;
    setCurrentClients(filtered.slice(indexOfFirstClient, indexOfLastClient));
  };

  const handleDelete = useCallback((id) => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/people/${id}`, {
      params: { user_id: username },
    })
    .then(() => {
      const updatedClients = clients.filter((client) => client.id !== id);
      setClients(updatedClients);
      if (updatedClients.length === 0) {
        setFilteredClients([]);
        setCurrentClients([]);
      } else {
        updateFilteredClients(); // Ensure this doesn't trigger multiple fetches
      }
    })
    .catch(error => {
      console.error("Error deleting client:", error);
    });
  }, [clients, username]);

  const handleStatusChange = useCallback((id, status) => {
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/people/${id}/status`, 
      { status }, 
      {
        params: { user_id: username },
      }
    )
    .then(() => {
      const updatedClients = clients.map(client => 
        client.id === id ? { ...client, status } : client
      );
      setClients(updatedClients);
      updateFilteredClients();
    })
    .catch(error => {
      console.error("Error updating status:", error);
    });
  }, [clients, username]);

  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setCurrentPage(1);
    }, 300),
    []
  );

  const handleSearch = (event) => {
    debouncedHandleSearch(event.target.value);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const backgroundColor = theme === "dark" ? "bg-gray-800" : "bg-white";
  const textColor = theme === "dark" ? "text-white" : "text-black";
  const inputBackgroundColor = theme === "dark" ? "bg-gray-700" : "bg-gray-100";
  const inputBorderColor = theme === "dark" ? "border-gray-600" : "border-gray-300";

  return (
    <div className={`absolute top-[285px] rounded-[20px] left-[351px] shadow-lg w-[1492px] flex flex-col items-start justify-start max-w-full text-xs font-montserrat ${textColor}`}>
      <div className={`w-[1110px] flex flex-row items-center justify-start p-4 box-border max-w-full ${inputBackgroundColor}`}>
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[24px] max-w-full">
          <div className="w-36 flex flex-row items-center justify-center gap-[12px]">
            <div className="relative font-medium inline-block min-w-[34px]">Show</div>
            <div className={`flex-[0.5814] rounded-lg flex flex-row items-center justify-start py-2 px-[9px] gap-[4px] mq450:w-[calc(100%_-_40px)] ${inputBackgroundColor}`}>
              <div className="relative font-medium inline-block min-w-[13px]">{itemsPerPage}</div>
              <img className="h-2 w-2 relative overflow-hidden shrink-0" alt="" src="/bicaretdownfill.svg" />
            </div>
            <div className="flex-1 relative font-medium inline-block min-w-[43px]">entries</div>
          </div>
          <div className={`flex-1 rounded-lg box-border flex flex-row items-center justify-start py-1.5 px-2 gap-[8px] min-w-[591px] max-w-full text-darkgray border-[1px] border-solid ${inputBorderColor} mq825:min-w-full`}>
            <img className="h-4 w-4 relative overflow-hidden shrink-0 min-h-[16px]" alt="" src="/akariconssearch.svg" />
            <input
              type="text"
              className={`flex-1 relative font-medium inline-block max-w-[calc(100%_-_24px)] ${textColor} ${inputBackgroundColor}`}
              placeholder="Search..."
              onChange={handleSearch}
              style={{ backgroundColor: 'transparent' }}
            />
          </div>
        </div>
      </div>
      <div className={`self-stretch overflow-x-auto flex flex-row items-center justify-center p-4 gap-[16px] ${backgroundColor}`}>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">ID</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start mq450:w-[calc(100%_-_40px)]">
          <b className="flex-1 relative">Name</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Date</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Evaluated Parent</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Child</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Child Collateral</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Parent Collateral</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[8px]">
          <b className="flex-1 relative">Status</b>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 text-center">
          <b className="flex-1 relative">Actions</b>
        </div>
      </div>
      {currentClients.length > 0 ? (
        currentClients.map((client) => (
          <ClientListItem
            key={client.id}
            id={client.id}
            name={client.name}
            date={client.date}
            evaluatedParentFirstName={client.evaluated_parent_first_name}
            evaluatedParentLastName={client.evaluated_parent_last_name}
            childFirstName={client.child_first_name}
            childLastName={client.child_last_name}
            childCollateralFirstName={client.child_collateral_first_name}
            childCollateralLastName={client.child_collateral_last_name}
            parentCollateralFirstName={client.parent_collateral_first_name}
            parentCollateralLastName={client.parent_collateral_last_name}
            status={client.status}
            waitlistDate={client.waitlist_date}
            onDelete={() => handleDelete(client.id)}
            onStatusChange={handleStatusChange}
          />
        ))
      ) : (
        <div className="w-full p-4 text-center text-gray-500">No clients found.</div>
      )}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={filteredClients.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Table;
