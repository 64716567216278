import React, { useState, useEffect } from 'react';

const ContactInfoPopup = ({
  showPopup,
  currentSection,
  tempContactInfo,
  handleInputChange,
  handleSave,
  handleCancel,
}) => {
  const [showOptionalFields, setShowOptionalFields] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setShowOptionalFields(false); // Reset optional fields visibility when popup is shown
    }
  }, [showPopup]);

  if (!showPopup) return null;

  // Define required fields based on the section
  const requiredFields = [];
  if (currentSection.section === 'Evaluated Parent') {
    requiredFields.push('firstName', 'lastName', 'honorific', 'maritalStatus');
  } else if (currentSection.section === 'Child') {
    requiredFields.push('firstName', 'lastName', 'age');
  } else if (currentSection.section === 'Child Collateral' || currentSection.section === 'Parent Collateral') {
    requiredFields.push('firstName', 'lastName', 'agencyName'); // Added agencyName as required
  }

  // Ensure that all required fields are filled
  const isSaveDisabled = requiredFields.some((field) => !tempContactInfo[field]);

  const relationshipOptions = [
    'Brother',
    'Sister',
    'Coworker',
    'Friend',
    'Grandparent',
    'Aunt',
    'Uncle',
    'Cousin',
    'Neighbor',
    'Other',
  ];

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-10"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }}
    >
      <div className="bg-white rounded-lg p-8">
        {/* Title Header */}
        <h2 className="text-xl font-semibold mb-4 text-black">
          Enter Contact Info for {currentSection.section}
        </h2>

        {/* Honorific (for Evaluated Parent and Collateral Contacts) */}
        {(currentSection.section === 'Evaluated Parent' || currentSection.section.includes('Collateral')) && (
          <select
            name="honorific"
            className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
            onChange={handleInputChange}
            value={tempContactInfo.honorific || ''}
          >
            <option value="">Select Honorific*</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </select>
        )}

        {/* First Name */}
        <input
          type="text"
          name="firstName"
          placeholder="First Name*"
          className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
          onChange={handleInputChange}
          value={tempContactInfo.firstName || ''}
        />

        {/* Last Name */}
        <input
          type="text"
          name="lastName"
          placeholder="Last Name*"
          className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
          onChange={handleInputChange}
          value={tempContactInfo.lastName || ''}
        />

        {/* Age (for Children) */}
        {currentSection.section === 'Child' && (
          <input
            type="number"
            name="age"
            placeholder="Age*"
            className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
            onChange={handleInputChange}
            value={tempContactInfo.age || ''}
          />
        )}

        {/* Relationship to Parents (for Parent Collateral) */}
        {currentSection.section === 'Parent Collateral' && (
          <select
            name="relationship_to_parents"
            className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
            onChange={handleInputChange}
            value={tempContactInfo.relationship_to_parents || ''}
          >
            <option value="">Select Relationship to Parents</option>
            {relationshipOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}

        {/* Marital Status (for Evaluated Parent) */}
        {currentSection.section === 'Evaluated Parent' && (
          <select
            name="maritalStatus"
            className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
            onChange={handleInputChange}
            value={tempContactInfo.maritalStatus || ''}
          >
            <option value="">Select Marital Status*</option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
        )}

        {/* Agency Name (for Child and Parent Collateral) */}
        {['Child Collateral', 'Parent Collateral'].includes(currentSection.section) && (
          <>
            <input
              type="text"
              name="agencyName"
              placeholder="Agency Name*"
              className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
              onChange={handleInputChange}
              value={tempContactInfo.agencyName || ''}
            />
            <input
              type="text"
              name="title"
              placeholder="Title (Optional)"
              className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
              onChange={handleInputChange}
              value={tempContactInfo.title || ''}
            />
          </>
        )}

        {/* Toggle optional fields */}
        <button
          className="mb-4 px-4 py-2 bg-blue-500 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-200"
          onClick={() => setShowOptionalFields((prev) => !prev)}
        >
          {showOptionalFields ? 'Hide Optional Fields' : 'Show Optional Fields'}
        </button>

        {/* Optional Fields */}
        {showOptionalFields && (
          <>
            {/* Optional Information Header */}
            <h3 className="text-lg font-semibold mb-4 text-black">
              Optional Information
            </h3>

            {/* Race Dropdown */}
            <select
              name="race"
              className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
              onChange={handleInputChange}
              value={tempContactInfo.race || ''}
            >
              <option value="">Select Race</option>
              <option value="White">White</option>
              <option value="Black or African American">
                Black or African American
              </option>
              <option value="Asian">Asian</option>
              <option value="Native American">Native American</option>
              <option value="Pacific Islander">Pacific Islander</option>
              <option value="Hispanic">Hispanic</option>
              <option value="Haitian">Haitian</option>
              <option value="Other">Other</option>
            </select>

            {/* Gender Dropdown */}
            <select
              name="gender"
              className="w-[95%] mb-4 p-2 border border-gray-300 rounded"
              onChange={handleInputChange}
              value={tempContactInfo.gender || ''}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-binary">Non-binary</option>
              <option value="Other">Other</option>
            </select>
          </>
        )}

        <div className="flex justify-end gap-4">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 rounded ${
              isSaveDisabled
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white'
            }`}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoPopup;
