import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import ContactInfoPopup from "./ContactInfoPopup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTheme } from "../../themeContext"; // Import the custom hook
import { useAuth } from "../../AuthContext"; // Import the useAuth hook
import { useParams } from 'react-router-dom';

const NewEvaluationInputs = ({ personData }) => {
  const navigate = useNavigate();
  const { theme } = useTheme(); // Get the current theme
  const { username } = useAuth(); // Get the username from the auth context
  const { id } = useParams(); // Get the person ID from the URL

  // Default sections
  const [showPopup, setShowPopup] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [contactInfo, setContactInfo] = useState({
    "Evaluated Parent": {
      firstName: "",
      lastName: "",
      honorific: "",
      maritalStatus: "",
    },
  });
  const [tempContactInfo, setTempContactInfo] = useState({});
  const [evaluationName, setEvaluationName] = useState("");
  const [allFilled, setAllFilled] = useState(false);

  // Dynamic children and contacts
  const [children, setChildren] = useState([]);
  const [childCollateralContacts, setChildCollateralContacts] = useState([]);
  const [parentCollateralContacts, setParentCollateralContacts] = useState([]);

  useEffect(() => {
    if (personData) {
      setContactInfo({
        "Evaluated Parent": {
          firstName: personData.evaluated_parent_first_name,
          lastName: personData.evaluated_parent_last_name,
          honorific: personData.evaluated_parent_honorific,
          maritalStatus: personData.evaluated_parent_marital_status,
        },
      });
      setEvaluationName(personData.name);
      setChildren(personData.children.map(child => ({
        firstName: child.first_name,
        lastName: child.last_name,
        race: child.race,
        gender: child.gender,
        age: child.age,
      })));
      setChildCollateralContacts(personData.child_collateral_contacts.map(contact => ({
        firstName: contact.first_name,
        lastName: contact.last_name,
        honorific: contact.honorific,
        race: contact.race,
        gender: contact.gender,
        maritalStatus: contact.marital_status,
        agencyName: contact.agency_name,
      })));
      setParentCollateralContacts(personData.parent_collateral_contacts.map(contact => ({
        firstName: contact.first_name,
        lastName: contact.last_name,
        honorific: contact.honorific,
        race: contact.race,
        gender: contact.gender,
        maritalStatus: contact.marital_status,
        agencyName: contact.agency_name,
        relationshipToParents: contact.relationship_to_parents,
      })));
    }
  }, [personData]);

  useEffect(() => {
    checkIfAllFilled();
  }, [contactInfo, children, evaluationName]);

  const handleSectionClick = (section, index = null, type = null) => {
    setCurrentSection({ section, index, type });
    if (type === "child") {
      setTempContactInfo(children[index] || {});
    } else if (type === "childCollateral") {
      setTempContactInfo(childCollateralContacts[index] || {});
    } else if (type === "parentCollateral") {
      setTempContactInfo(parentCollateralContacts[index] || {});
    } else {
      setTempContactInfo(contactInfo[section] || {});
    }
    setShowPopup(true);
  };

  const handleInputChange = (e) => {
    setTempContactInfo((prevInfo) => ({
      ...prevInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const checkIfAllFilled = () => {
    // Evaluated Parent must have firstName, lastName, honorific, and maritalStatus filled out
    const evaluatedParentFilled =
      contactInfo["Evaluated Parent"] &&
      contactInfo["Evaluated Parent"].firstName &&
      contactInfo["Evaluated Parent"].lastName &&
      contactInfo["Evaluated Parent"].honorific &&
      contactInfo["Evaluated Parent"].maritalStatus;

    // At least one child must be filled with firstName, lastName, and age
    const childrenFilled =
      children.length > 0 &&
      children.every((child) => child.firstName && child.lastName && child.age);

    // Check if Evaluation Name is filled
    setAllFilled(evaluatedParentFilled && childrenFilled && evaluationName);
  };

  const handleDelete = (section, e, index = null, type = null) => {
    e.stopPropagation();
    if (type === "child") {
      setChildren((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "childCollateral") {
      setChildCollateralContacts((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "parentCollateral") {
      setParentCollateralContacts((prev) => prev.filter((_, i) => i !== index));
    } else {
      setContactInfo((prevInfo) => {
        const newInfo = { ...prevInfo };
        if (section === "Evaluated Parent") {
          newInfo[section] = {
            firstName: "",
            lastName: "",
            honorific: "",
            maritalStatus: "",
          };
        } else {
          delete newInfo[section];
        }
        return newInfo;
      });
    }
  };

  const handleSave = () => {
    const { section, index, type } = currentSection;

    if (type === "child") {
      const updatedChildren = [...children];
      updatedChildren[index] = tempContactInfo;
      setChildren(updatedChildren);
    } else if (type === "childCollateral") {
      const updatedContacts = [...childCollateralContacts];
      updatedContacts[index] = tempContactInfo;
      setChildCollateralContacts(updatedContacts);
    } else if (type === "parentCollateral") {
      const updatedContacts = [...parentCollateralContacts];
      updatedContacts[index] = tempContactInfo;
      setParentCollateralContacts(updatedContacts);
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        [section]: tempContactInfo,
      }));
    }

    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const handleSubmit = (isDraft = false) => {
    const status = isDraft ? "draft" : "complete";
  
    if (allFilled || isDraft) {  // Allow saving draft even if fields aren't fully filled
      const newPerson = {
        id: id,  // Include the person ID if it exists
        user_id: username,
        name: evaluationName,
        date: new Date().toLocaleDateString(),
        status,  // Pass status as 'draft' or 'complete'
        evaluated_parent_first_name: contactInfo["Evaluated Parent"]?.firstName,
        evaluated_parent_last_name: contactInfo["Evaluated Parent"]?.lastName,
        evaluated_parent_honorific: contactInfo["Evaluated Parent"]?.honorific,
        evaluated_parent_marital_status: contactInfo["Evaluated Parent"]?.maritalStatus,
        children: children.map((child) => ({
          first_name: child.firstName,
          last_name: child.lastName,
          race: child.race,
          gender: child.gender,
          age: child.age,
        })),
        child_collateral_contacts: childCollateralContacts.map((contact) => ({
          first_name: contact.firstName,
          last_name: contact.lastName,
          honorific: contact.honorific,
          race: contact.race,
          gender: contact.gender,
          marital_status: contact.maritalStatus,
          agency_name: contact.agencyName,
          title: contact.title, // Add this line
        })),
        parent_collateral_contacts: parentCollateralContacts.map((contact) => ({
          first_name: contact.firstName,
          last_name: contact.lastName,
          honorific: contact.honorific,
          race: contact.race,
          gender: contact.gender,
          marital_status: contact.maritalStatus,
          agency_name: contact.agencyName,
          relationship_to_parents: contact.relationshipToParents,
          title: contact.title, // Add this line
        })),
      };
  
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/people`, newPerson)
        .then(response => {
          console.log("Person saved successfully:", response.data);
          navigate("/"); // Redirect to the home page or another page
        })
        .catch(error => {
          console.error("Error saving person:", error);
        });
    }
  };

  const addNewChild = () => {
    setChildren([...children, { firstName: "", lastName: "", race: "", gender: "", age: "" }]);
  };

  const addNewChildCollateral = () => {
    setChildCollateralContacts([
      ...childCollateralContacts,
      {
        firstName: "",
        lastName: "",
        honorific: "",
        race: "",
        gender: "",
        maritalStatus: "",
        age: "",
      },
    ]);
  };

  const addNewParentCollateral = () => {
    setParentCollateralContacts([
      ...parentCollateralContacts,
      {
        firstName: "",
        lastName: "",
        honorific: "",
        race: "",
        gender: "",
        maritalStatus: "",
        age: "",
        relationship_to_parents: "",
      },
    ]);
  };

  const textColor = theme === "dark" ? "text-white" : "text-black";
  const borderColor = theme === "dark" ? "border-white" : "border-black";
  const sectionFilledBgColor =
    theme === "dark" ? "bg-color-primary-100" : "bg-color-primary-100";
  const sectionTextColor = theme === "dark" ? "text-white" : "text-white";
  const deleteButtonBgColor = theme === "dark" ? "bg-gray-900" : "bg-white";

  const getSectionBgColor = (sectionInfo) => {
    return sectionInfo.firstName ? sectionFilledBgColor : "bg-thistle";
  };

  return (
    <div className="absolute top-[304px] left-[351px] w-[1278px] h-auto flex flex-col items-start justify-start gap-[38px] pb-[40px] mq1280:w-[900px]">
      {/* Evaluation Name section */}
      <div
        className={`w-[1258px] h-auto flex flex-col font-poppins ${textColor} mq1280:w-[900px]`}
      >
        <div className="h-[25.3px] w-full relative font-semibold text-[30px] mb-[20px]">
          Evaluation Name*
        </div>
        <input
          type="text"
          className={`w-full h-[48.7px] mt-[20px] rounded-[10.12px] border ${borderColor} pl-[30px] }  mq1280:w-[860px] ${textColor} ${
            theme === "dark" ? "bg-gray-800" : "bg-white"
          }`}
          placeholder="Enter Evaluation Name"
          value={evaluationName}
          onChange={(e) => setEvaluationName(e.target.value)}
        />
      </div>

      {/* Evaluated Parent Section (always present) */}
      <div
        className={`w-[1278px] h-auto rounded-[20.23px] my-[20px] ${getSectionBgColor(
          contactInfo["Evaluated Parent"]
        )} overflow-hidden shrink-0 flex flex-row items-center justify-between pt-[21.9px] pb-[17.3px] pr-[28.3px] pl-8 box-border text-left font-poppins cursor-pointer ${sectionTextColor}   mq1280:w-[900px]`}
        onClick={() => handleSectionClick("Evaluated Parent")}
      >
        <div className="h-[25.3px] w-auto relative font-semibold inline-block shrink-0">
          Evaluated Parent*
        </div>
        {contactInfo["Evaluated Parent"]?.firstName && (
          <div className="flex items-center">
            <span className="block text-sm mr-4">
              {contactInfo["Evaluated Parent"].firstName}{" "}
              {contactInfo["Evaluated Parent"].lastName}
            </span>
            <button
              className={`${deleteButtonBgColor} p-2 rounded-full`}
              onClick={(e) => handleDelete("Evaluated Parent", e)}
            >
              <FaTrash className="text-color-primary-100" />
            </button>
          </div>
        )}
      </div>

      {/* Dynamic Children Section */}
      <div className={`w-[1278px] h-auto ${textColor} mq1280:w-[900px]`}>
        <div className="font-semibold">Children*</div>
        {children.map((child, index) => (
          <div
            key={index}
            className={`w-[1278px] h-auto rounded-[20.23px] my-[20px] ${getSectionBgColor(
              child
            )} overflow-hidden shrink-0 flex flex-row items-center justify-between pt-[21.9px] pb-[17.3px] pr-[28.3px] pl-8 box-border text-left font-poppins cursor-pointer ${sectionTextColor} mq1280:w-[900px]`}
            onClick={() => handleSectionClick("Child", index, "child")}
          >
            <div className="h-[25.3px] w-auto relative font-semibold inline-block shrink-0">
              Child {index + 1}*
            </div>
            {child.firstName && (
              <div className="flex items-center">
                <span className="block text-sm mr-4">
                  {child.firstName} {child.lastName}
                </span>
                <button
                  className={`${deleteButtonBgColor} p-2 rounded-full`}
                  onClick={(e) => handleDelete("Child", e, index, "child")}
                >
                  <FaTrash className="text-color-primary-100" />
                </button>
              </div>
            )}
          </div>
        ))}
        <button
          onClick={addNewChild}
          className="bg-color-primary-100 hover:bg-purple text-white font-bold py-2 px-4 rounded-full mt-4 transition-all duration-300 ease-in-out shadow-md"
        >
          Add Child
        </button>
      </div>

      {/* Dynamic Child Collateral Contacts */}
      <div className={`w-[1278px] h-auto ${textColor} mq1280:w-[900px]`}>
        <div className="font-semibold">Child Collateral Contacts</div>
        {childCollateralContacts.map((contact, index) => (
          <div
            key={index}
            className={`w-[1278px] h-auto rounded-[20.23px] my-[20px] ${getSectionBgColor(
              contact
            )} overflow-hidden shrink-0 flex flex-row items-center justify-between pt-[21.9px] pb-[17.3px] pr-[28.3px] pl-8 box-border text-left font-poppins cursor-pointer ${sectionTextColor} mq1280:w-[900px]`}
            onClick={() => handleSectionClick("Child Collateral", index, "childCollateral")}
          >
            <div className="h-[25.3px] w-auto relative font-semibold inline-block shrink-0">
              Child Collateral Contact {index + 1}
            </div>
            {contact.firstName && (
              <div className="flex items-center">
                <span className="block text-sm mr-4">
                  {contact.firstName} {contact.lastName}
                </span>
                <button
                  className={`${deleteButtonBgColor} p-2 rounded-full`}
                  onClick={(e) => handleDelete("Child Collateral", e, index, "childCollateral")}
                >
                  <FaTrash className="text-color-primary-100" />
                </button>
              </div>
            )}
          </div>
        ))}
        <button
          onClick={addNewChildCollateral}
          className="bg-color-primary-100 hover:bg-purple text-white font-bold py-2 px-4 rounded-full mt-4 transition-all duration-300 ease-in-out shadow-md"
        >
          Add Child Collateral Contact
        </button>
      </div>

      {/* Dynamic Parent Collateral Contacts */}
      <div className={`w-[1278px] h-auto ${textColor} mq1280:w-[900px]`}>
        <div className="font-semibold">Parent Collateral Contacts</div>
        {parentCollateralContacts.map((contact, index) => (
          <div
            key={index}
            className={`w-[1278px] h-auto rounded-[20.23px] my-[20px] ${getSectionBgColor(
              contact
            )} overflow-hidden shrink-0 flex flex-row items-center justify-between pt-[21.9px] pb-[17.3px] pr-[28.3px] pl-8 box-border text-left font-poppins cursor-pointer ${sectionTextColor} mq1280:w-[900px]`}
            onClick={() => handleSectionClick("Parent Collateral", index, "parentCollateral")}
          >
            <div className="h-[25.3px] w-auto relative font-semibold inline-block shrink-0">
              Parent Collateral Contact {index + 1}
            </div>
            {contact.firstName && (
              <div className="flex items-center">
                <span className="block text-sm mr-4">
                  {contact.firstName} {contact.lastName}
                </span>
                <button
                  className={`${deleteButtonBgColor} p-2 rounded-full`}
                  onClick={(e) => handleDelete("Parent Collateral", e, index, "parentCollateral")}
                >
                  <FaTrash className="text-color-primary-100" />
                </button>
              </div>
            )}
          </div>
        ))}
        <button
          onClick={addNewParentCollateral}
          className="bg-color-primary-100 hover:bg-purple text-white font-bold py-2 px-4 rounded-full mt-4 transition-all duration-300 ease-in-out shadow-md"
        >
          Add Parent Collateral Contact
        </button>
      </div>

      {/* Save and Submit Buttons */}
      <div className="flex justify-end w-full mt-8">
        <button
          onClick={() => handleSubmit(true)}
          className="bg-color-primary-100 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full mr-4 transition-all duration-300 ease-in-out shadow-md"
        >
          Save as Draft
        </button>
        <button
          onClick={() => handleSubmit(false)}
          className={`font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out shadow-md ${
            allFilled ? 'bg-color-primary-100 hover:bg-purple text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={!allFilled}
        >
          Submit
        </button>
      </div>

      {/* Popup for editing contact info */}
      {showPopup && (
        <ContactInfoPopup
          showPopup={showPopup}
          currentSection={currentSection}
          tempContactInfo={tempContactInfo}
          handleInputChange={handleInputChange}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};


export default NewEvaluationInputs;
