import React, { useState } from 'react';
import { useAuth } from "../../AuthContext";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const Create_Evaulations = () => {
  const { username } = useAuth();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('document', file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/document_upload?user_id=${username}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        if (location.pathname === '/') {
          window.location.reload();
        } else {
          navigate('/');
        }
      }, 2000);
    } catch (error) {
      setMessage(error.response?.data?.error || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const truncateFileName = (name, maxLength = 20) => {
    if (name.length <= maxLength) return name;
    const extension = name.split('.').pop();
    const nameWithoutExtension = name.slice(0, -(extension.length + 1));
    return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
  };

  return (
    <div className="flex-col font-[Poppins]">
      <form onSubmit={handleSubmit} className="">
        <div className="flex items-center mb-4">
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            accept=".doc,.docx,.pdf"
            className="hidden"
          />
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex-grow mr-2 text-center overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {file ? truncateFileName(file.name) : 'Select a file'}
          </label>
          <button
            type="submit"
            className="bg-color-primary-100 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="w-6 h-6 border-t-2 border-white border-solid rounded-full animate-spin"></div>
            ) : (
              'Upload'
            )}
          </button>
        </div>
      </form>
      {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
    </div>
  );
};

export default Create_Evaulations;
