import React, { useState, useEffect } from 'react';
import { useTheme } from "../../themeContext";
import { useAuth } from '../../AuthContext';
import TopBar from "../../components/TopBar/TopBar";
import SideMenu from "../../components/SideMenu/SideMenu";
import InterviewContainer from "./InterviewContainer";
import FloatingButtons from "./FloatingButtons";
import Header from "./Header";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PersonInfoForm from './PersonInfoForm'; // Import the new component

const SectionEvaluations = () => {
  const { theme } = useTheme();
  const { username } = useAuth();
  const { id } = useParams(); 

  const [personInfo, setPersonInfo] = useState({
    name: '',
    date: '',
    evaluated_parent_first_name: '',
    evaluated_parent_last_name: '',
    child_first_name: '',
    child_last_name: '',
    child_collateral_first_name: '',
    child_collateral_last_name: '',
    parent_collateral_first_name: '',
    parent_collateral_last_name: '',
    status: '',
    waitlist_date: '',
    evaluated_parent_honorific: '',
    child_collateral_honorific: '',
    parent_collateral_honorific: '',
    other_contact_honorific: '',
    collateral_contacts: [] // Include collateral_contacts as part of the initial state
  });
  
  const [interviews, setInterviews] = useState([]);

  const [filter, setFilter] = useState("all");
  const [sort, setSort] = useState("mostRecent");

  useEffect(() => {
    if (id && username) {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/people/${id}/info`, {
        params: {
          user_id: username,
        },
      })
        .then(response => setPersonInfo(response.data))
        .catch(error => console.error('Error fetching person info:', error));

      axios.get(`${process.env.REACT_APP_BACKEND_URL}/interviews/${id}`, {
        params: {
          user_id: username,
        },
      })
        .then(response => setInterviews(response.data))
        .catch(error => console.error('Error fetching interviews:', error));
    }
  }, [id, username]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonInfo(prevInfo => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleUpdatePersonInfo = (updatedPersonInfo) => {  // Updated to accept updatedPersonInfo
    axios.put(`${process.env.REACT_APP_BACKEND_URL}/people/${id}/info`, updatedPersonInfo, {
      params: {
        user_id: username,
      },
    })
      .then(response => {
        alert('Person information updated successfully');
      })
      .catch(error => console.error('Error updating person info:', error));
  };

  const filteredInterviews = interviews
    .filter(interview => {
      const date = new Date(interview.interview_date);
      const today = new Date();
      if (filter === "day") {
        return date.toDateString() === today.toDateString();
      } else if (filter === "year") {
        return date.getFullYear() === today.getFullYear();
      }
      return true;
    })
    .sort((a, b) => {
      const dateA = new Date(a.interview_date);
      const dateB = new Date(b.interview_date);
      if (sort === "mostRecent") {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });

  return (
    <div className={`min-h-screen overflow-y-auto overflow-x-hidden relative text-left text-sm font-poppins ${theme === "dark" ? "bg-[#3A3838] text-white" : "bg-white text-gray2"}`}>
      <TopBar title={`${personInfo.name}`} backUrl={`/`} />
      <div className="flex">
        <SideMenu theme={theme} />
        <div className="flex-grow">
          <Header />
          <FloatingButtons filter={filter} setFilter={setFilter} setSort={setSort} id={id} />
          
          <div className="absolute top-[304px] left-[351px] w-[1278px] h-auto p-6 ">
            
            {/* Use the PersonInfoForm component */}
            <PersonInfoForm 
              personInfo={personInfo} 
              handleInputChange={handleInputChange} 
              handleUpdatePersonInfo={handleUpdatePersonInfo} 
            />

            <div className="mt-[150px]"> 
              <InterviewContainer people={filteredInterviews} id={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionEvaluations;
