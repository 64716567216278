import React, { useState, useEffect } from 'react';

const PersonInfoForm = ({ personInfo, handleInputChange, handleUpdatePersonInfo }) => {
  const [isMinimized, setIsMinimized] = useState(true);
  const [collateralContacts, setCollateralContacts] = useState(personInfo.collateral_contacts || []);
  const [childInfo, setChildInfo] = useState({
    first_name: personInfo.child_first_name || '',
    last_name: personInfo.child_last_name || ''
  });

  useEffect(() => {
    // Update collateralContacts and childInfo state when personInfo changes (e.g., after initial load)
    setCollateralContacts(personInfo.collateral_contacts || []);
    setChildInfo({
      first_name: personInfo.child_first_name || '',
      last_name: personInfo.child_last_name || ''
    });
  }, [personInfo]);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleAddContact = () => {
    setCollateralContacts([...collateralContacts, { first_name: '', last_name: '', honorific: '' }]);
  };

  const handleCollateralContactChange = (index, field, value) => {
    const newContacts = [...collateralContacts];
    newContacts[index][field] = value;
    setCollateralContacts(newContacts);
  };

  const handleChildInputChange = (field, value) => {
    setChildInfo(prevChildInfo => ({
      ...prevChildInfo,
      [field]: value
    }));
  };

  const handleSave = () => {
    const updatedPersonInfo = {
      ...personInfo,
      child_first_name: childInfo.first_name,
      child_last_name: childInfo.last_name,
      collateral_contacts: collateralContacts
    };
    console.log('Updated Person Info:', updatedPersonInfo); // Debugging line
    handleUpdatePersonInfo(updatedPersonInfo);
  };

  return (
    <div className='flex flex-col mb-[20px]'>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-4">Person Information</h2>
        <button 
          onClick={toggleMinimize} 
          className="px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-700"
        >
          {isMinimized ? "Maximize" : "Minimize"}
        </button>
      </div>
      
      {!isMinimized && (
        <>
          <div className="grid grid-cols-2 gap-4">
            {[
              { label: "Name", name: "name" },
              { label: "Date", name: "date" },
              { label: "Evaluated Parent First Name", name: "evaluated_parent_first_name" },
              { label: "Evaluated Parent Last Name", name: "evaluated_parent_last_name" },
              { label: "Status", name: "status" },
              { label: "Waitlist Date", name: "waitlist_date" },
              { label: "Evaluated Parent Honorific", name: "evaluated_parent_honorific" },
            ].map(({ label, name }) => (
              <div key={name}>
                <label className="block mb-1">{label}:</label>
                <input
                  type="text"
                  name={name}
                  value={personInfo[name]}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            ))}

            {/* Child Information */}
            <div>
              <label className="block mb-1">Child First Name:</label>
              <input
                type="text"
                value={childInfo.first_name}
                onChange={(e) => handleChildInputChange('first_name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div>
              <label className="block mb-1">Child Last Name:</label>
              <input
                type="text"
                value={childInfo.last_name}
                onChange={(e) => handleChildInputChange('last_name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          {/* Collateral Contacts */}
          <div className="mt-4">
            <h3 className="text-xl font-semibold">Collateral Contacts</h3>
            {collateralContacts.map((contact, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 mb-2">
                <input
                  type="text"
                  placeholder="First Name"
                  value={contact.first_name}
                  onChange={(e) => handleCollateralContactChange(index, 'first_name', e.target.value)}
                  className="p-2 border border-gray-300 rounded"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={contact.last_name}
                  onChange={(e) => handleCollateralContactChange(index, 'last_name', e.target.value)}
                  className="p-2 border border-gray-300 rounded"
                />
                <input
                  type="text"
                  placeholder="Honorific"
                  value={contact.honorific}
                  onChange={(e) => handleCollateralContactChange(index, 'honorific', e.target.value)}
                  className="p-2 border border-gray-300 rounded"
                />
                {/* Include the id for existing contacts (hidden field) */}
                {contact.id && (
                  <input
                    type="hidden"
                    value={contact.id}
                    className="hidden"
                  />
                )}
              </div>
            ))}
            <button 
              onClick={handleAddContact} 
              className="mt-2 px-4 py-2 rounded bg-green-500 text-white hover:bg-green-700"
            >
              + Add Contact
            </button>
          </div>

          <button
            onClick={handleSave}
            className="mt-4 px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700"
          >
            Save Changes
          </button>
        </>
      )}
    </div>
  );
};

export default PersonInfoForm;
