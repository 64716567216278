import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const EvaulationsItem = ({ className = "", name, date, id, status }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (status === "draft") {
      navigate(`/new-evaluations/${encodeURIComponent(id)}`);
    } else {
      navigate(`/evaluation/${encodeURIComponent(id)}`);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    navigate(`/new-evaluations/${encodeURIComponent(id)}`);
  };

  return (
    <div
      className={`w-[1278px] h-[68px] cursor-pointer rounded-[20.23px] bg-thistle overflow-hidden shrink-0 flex flex-row items-center justify-between pt-[21.9px] pb-[17.3px] pr-[28.3px] pl-8 box-border text-left text-base-9 text-white font-poppins ${className}  mq1280:w-[900px]`}
      onClick={handleClick}
    >
      <div className="h-[25.3px] w-[199px] relative font-semibold inline-block shrink-0">
        {name}
      </div>
      <div className="flex flex-row items-center justify-end gap-[20px] text-2xs-1 text-black1">
        <div className="h-[24.5px] w-[94.4px]">
          <div className="w-[94.4px] h-[22.8px] rounded-[10.12px] bg-white overflow-hidden flex flex-row items-center justify-center">
            <div className="h-[16.9px] w-[64.9px] relative inline-block">
              {date}
            </div>
          </div>
        </div>
        {status !== "draft" && (
          <button
            className="px-2 py-1 bg-blue-500 text-white rounded"
            onClick={handleEditClick}
          >
            Edit
          </button>
        )}
        <img
          className="h-[28.7px] w-[28.7px] relative overflow-hidden shrink-0"
          alt=""
          src="/makiarrow.svg"
        />
      </div>
    </div>
  );
};

EvaulationsItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default EvaulationsItem;
