import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from "../../AuthContext"; // Adjust the import path as necessary
import { FaTrash, FaSearch } from 'react-icons/fa'; // Import icons

const AdminTable = () => {
  const [personsWithUsers, setPersonsWithUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const { username } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [userSearchTerms, setUserSearchTerms] = useState({});
  const [filteredUsers, setFilteredUsers] = useState({});

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const personsResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/persons-with-users`, { params: { user_id: username } });
      setPersonsWithUsers(personsResponse.data);
      
      try {
        const usersResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users`, { params: { user_id: username } });
        setAllUsers(usersResponse.data);
      } catch (userErr) {
        console.error('Error fetching all users:', userErr);
        const uniqueUsers = [...new Set(personsResponse.data.flatMap(person => person.users))];
        setAllUsers(uniqueUsers);
      }
      
      setError(null);
    } catch (err) {
      setError('Failed to fetch data. Please try again later.');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddUser = async (personId, userId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/add-user-to-person`, { person_id: personId, user_id: userId });
      
      // Update state locally
      setPersonsWithUsers(prevPersons => 
        prevPersons.map(person => {
          if (person.id === personId) {
            const newUser = allUsers.find(user => user.id === userId);
            return {
              ...person,
              users: [...person.users, newUser]
            };
          }
          return person;
        })
      );
    } catch (err) {
      setError('Failed to add user. Please try again.');
      console.error('Error adding user:', err);
    }
  };

  const handleRemoveUser = async (personId, userId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/remove-user-from-person`, { person_id: personId, user_id: userId });
      
      // Update state locally
      setPersonsWithUsers(prevPersons => 
        prevPersons.map(person => {
          if (person.id === personId) {
            return {
              ...person,
              users: person.users.filter(user => user.id !== userId)
            };
          }
          return person;
        })
      );
    } catch (err) {
      setError('Failed to remove user. Please try again.');
      console.error('Error removing user:', err);
    }
  };

  const filteredPersons = personsWithUsers.filter(person =>
    person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    person.users.some(user => 
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleUserSearch = (personId, searchTerm) => {
    setUserSearchTerms(prev => ({ ...prev, [personId]: searchTerm }));
    const filtered = allUsers.filter(user => 
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(prev => ({ ...prev, [personId]: filtered }));
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
    </div>;
  }

  if (error) {
    return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong className="font-bold">Error!</strong>
      <span className="block sm:inline"> {error}</span>
    </div>;
  }

  return (
    <div>
      <div className="mb-4 flex items-center">
        <FaSearch className="text-gray-400 mr-2" />
        <input
          type="text"
          placeholder="Search by name or user..."
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">ID</th>
              <th scope="col" className="px-6 py-3">Name</th>
              <th scope="col" className="px-6 py-3">Agency Name</th>
              <th scope="col" className="px-6 py-3">Date</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Users</th>
              <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPersons.map((person) => (
              <tr key={person.id} className="bg-white border-b hover:bg-gray-50">
                <td className="px-6 py-4">{person.id}</td>
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{person.name}</td>
                <td className="px-6 py-4">{person.agency_name}</td>
                <td className="px-6 py-4">{person.date}</td>
                <td className="px-6 py-4">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    person.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}>
                    {person.status}
                  </span>
                </td>
                <td className="px-6 py-4">
                  {person.users.map((user) => (
                    <div key={user.id} className="flex items-center space-x-2 mb-1">
                      <span>{user.username} ({user.email})</span>
                      <button
                        onClick={() => handleRemoveUser(person.id, user.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                  <div className="mt-2">
                    <input
                      type="text"
                      placeholder="Search users to add..."
                      value={userSearchTerms[person.id] || ''}
                      onChange={(e) => handleUserSearch(person.id, e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    {userSearchTerms[person.id] && (
                      <ul className="mt-1 max-h-40 overflow-y-auto bg-white border border-gray-300 rounded-lg">
                        {(filteredUsers[person.id] || [])
                          .filter((user) => !person.users.some((u) => u.id === user.id))
                          .map((user) => (
                            <li 
                              key={user.id}
                              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                handleAddUser(person.id, user.id);
                                handleUserSearch(person.id, '');
                              }}
                            >
                              {user.username} ({user.email})
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminTable;
