import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext"; // Import useAuth hook

const RegisterComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");

  const navigate = useNavigate();
  const { handleLogin } = useAuth(); // Use the handleLogin function from context

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\\.,;:\s@\"]+\.)+[^<>()[\]\\.,;:\@\"]{2,})$/i;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setUsername(inputEmail);

    if (!validateEmail(inputEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/register`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        handleLogin(true, username); // Set login status and username
        navigate("/dashboard");
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError("Registration failed. Please try again.");
      }
    }
  };

  return (
    <div className="flex w-full h-screen">
      <div className="w-[35%] h-full bg-white sm:w-[100%]">
        <div className="flex flex-col items-start justify-start max-w-full text-left text-black p-[80px]">
          <div className="flex flex-col self-stretch items-start justify-start p-0 max-w-full">
            <div className="font-poppins mt-[60px] mb-[10px] font-semibold text-[28px]">
              Get Started Now
            </div>
            <div className="font-poppins font-medium mb-[30px] text-[14px] text-gray-500">
              Please sign up to continue.
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="m-0 self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full"
          >
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[25px]">
              <div className=" flex self-stretch flex-col items-start justify-start text-left text-[17.5px] text-black font-poppins">
                <div className="flex flex-row items-start justify-start py-2">
                  Email*
                </div>
                <div className="self-stretch h-10 rounded-[5px] mt-[5px] box-border overflow-hidden flex flex-row items-start justify-start py-[10px] pl-[12.5px] border-gray-400 border-[1px] border-solid bg-white">
                  <input
                    className="flex-grow font-poppins outline-none font-medium text-[12.5px] bg-white"
                    placeholder="Email"
                    type="email"
                    value={username}
                    onChange={handleEmailChange}
                  />
                </div>
                {emailError && (
                  <div className="font-medium text-[12.5px] text-red-600 pt-5">
                    {emailError}
                  </div>
                )}

                <div className="flex flex-row items-start justify-start py-2">
                  Password*
                </div>
                <div className="self-stretch h-10 rounded-[5px] mt-[5px] box-border overflow-hidden flex flex-row items-start justify-start py-[10px] pl-[12.5px] border-gray-400 border-[1px] border-solid bg-white">
                  <input
                    className="flex-grow font-poppins outline-none font-medium text-[12.5px] bg-white"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex mb-[40px] flex-row items-center justify-start py-0 pr-5 pl-0 gap-[8px]">
                <input
                  className="m-0 h-[12.3px] w-[11.3px] relative rounded-[2.5px] box-border border-[1.3px] border-solid border-white"
                  type="checkbox"
                />
                <div className="relative text-[11.3px] font-medium font-poppins text-black text-left">
                  {`I agree to the `}
                  <span className="[text-decoration:underline]">
                    {`terms & policy`}
                  </span>
                </div>
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm mb-2">{error}</div>
            )}

            <button
              type="submit"
              className="cursor-pointer border-none p-0 rounded-[18px] bg-[#478cff] self-stretch h-11 relative max-w-full flex items-center justify-center"
            >
              <span className="absolute text-[16px] font-poppins text-white text-left z-10 px-2 py-1">
                Sign Up
              </span>
            </button>
          </form>

          <div className="self-stretch flex flex-col items-center justify-start text-[17.5px]">
            <div className="flex items-start justify-start pl-2 box-border]">
              <div className="flex items-center justify-center">
                <div className="font-sans font-semibold whitespace-pre-wrap my-[20px]">
                  <span>{`Have an account? `}</span>
                  <Link
                    to="/login"
                    className="text-[18px] text-[#478cff] no-underline"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[65%] h-full bg-[#efefef] sm:hidden">
        {/* Second box content */}
      </div>
    </div>
  );
};

export default RegisterComponent;
