import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Earning from "./Earning";
import SideMenu from "../../components/SideMenu/SideMenu";
import { useTheme } from "../../themeContext";
import { useAuth } from "../../AuthContext";
import axios from "axios";

const COLORS = [
  "#FFB3BA",
  "#FFDFBA",
  "#FFFFBA",
  "#BAFFC9",
  "#BAE1FF",
  "#D3A7FF",
  "#FFABAB",
  "#FFC3A0",
  "#FF677D",
  "#D4F1F4",
  "#A2D5F2",
  "#85A9FF",
];

const CustomBackground = (props) => {
  const { fill, x, y, width, height } = props;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      rx={10}
      ry={10}
    />
  );
};

const Dashboard = () => {
  const { theme } = useTheme();
  const { username, isAdmin } = useAuth();
  const [raceData, setRaceData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [maritalStatusData, setMaritalStatusData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/demographics`, {
        params: { user_id: username },
      })
      .then((response) => {
        const { race, gender, age, marital_status } = response.data;
        setRaceData(
          Object.keys(race).map((key) => ({ name: key, value: race[key] }))
        );
        setGenderData(
          Object.keys(gender).map((key) => ({ name: key, value: gender[key] }))
        );
        setAgeData(
          Object.keys(age).map((key) => ({ name: key, value: age[key] }))
        );
        setMaritalStatusData(
          Object.keys(marital_status).map((key) => ({
            name: key,
            value: marital_status[key],
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching demographics data:", error);
      });
  }, [username, isAdmin]);

  return (
    <div
      className={`w-full min-h-screen overflow-hidden leading-normal tracking-normal text-left text-sm text-gray-700 font-sans ${
        theme === "dark" ? "bg-[#3A3838]" : "bg-purple-100"
      }`}
    >
      <SideMenu />
      <Earning />
      <main className="w-[1400px] left-[350px] absolute top-[215px] mx-auto grid grid-cols-2 grid-rows-2 gap-4 p-4 mq1280:w-[75%] mq1280:left-[300px] mq1280:p-3 mq1280:grid-cols-1">
        <div className="p-4 bg-white rounded-[30px] shadow-lg">
          <h3 className="text-lg font-medium mb-4">Race</h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={raceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Bar
                dataKey="value"
                fill="#B0C4DE"
                barSize={15}
                radius={[10, 10, 10, 10]}
                background={<CustomBackground fill="#E6E6FA" />}
              >
                {raceData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#4682B4" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="p-4 bg-white rounded-[30px] shadow-lg">
          <h3 className="text-lg font-medium mb-4">Age</h3>
          <ResponsiveContainer width="100%" height={200} className="mq1280:h-[150px]">
            <BarChart data={ageData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Bar
                dataKey="value"
                fill="#FFECB3"
                barSize={15}
                radius={[10, 10, 10, 10]}
                background={<CustomBackground fill="#FFF8DC" />}
              >
                {ageData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#FFD700" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="p-4 bg-white rounded-[30px] shadow-lg">
          <h3 className="text-lg font-medium mb-4">Marital Status</h3>
          <ResponsiveContainer width="100%" height={200} className="mq1280:h-[150px]">
            <BarChart data={maritalStatusData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Bar
                dataKey="value"
                fill="#D3D3D3"
                barSize={15}
                radius={[10, 10, 10, 10]}
                background={<CustomBackground fill="#E0E0E0" />}
              >
                {maritalStatusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill="#A9A9A9" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="p-4 bg-white rounded-[30px] shadow-lg">
          <h3 className="text-lg font-medium mb-4">Gender</h3>
          <ResponsiveContainer width="100%" height={200} className="mq1280:h-[150px]">
            <PieChart>
              <Pie
                data={genderData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {genderData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
