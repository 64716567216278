import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FiLoader, FiChevronDown, FiChevronUp, FiAlertCircle } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown

const OverallReport = () => {
    const { id } = useParams();
    const { username } = useAuth();
    const [report, setReport] = useState("");
    const [error, setError] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [statusCheckInterval, setStatusCheckInterval] = useState(null);

    const checkStatus = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/people/${id}/paperwork/status`,
                { params: { user_id: username } }
            );

            setProgress(response.data.progress);

            if (response.data.status === 'completed') {
                setReport(response.data.report);
                setIsVisible(true);
                setIsLoading(false);
                clearInterval(statusCheckInterval);
                setStatusCheckInterval(null);
            } else if (response.data.status === 'error') {
                setError(response.data.error);
                setIsLoading(false);
                clearInterval(statusCheckInterval);
                setStatusCheckInterval(null);
            }
        } catch (err) {
            setError("Error checking report status");
            setIsLoading(false);
            clearInterval(statusCheckInterval);
            setStatusCheckInterval(null);
        }
    };

    const fetchReport = async () => {
        setIsLoading(true);
        setError(null);
        try {
            // Start report generation
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/people/${id}/paperwork`,
                null,
                { params: { user_id: username } }
            );

            // Start polling for status
            const intervalId = setInterval(checkStatus, 3000);
            setStatusCheckInterval(intervalId);
        } catch (err) {
            setError("Error starting report generation");
            setIsLoading(false);
        }
    };


    // Cleanup interval on component unmount
    useEffect(() => {
        return () => {
            if (statusCheckInterval) {
                clearInterval(statusCheckInterval);
            }
        };
    }, [statusCheckInterval]);


    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const copyToClipboard = () => {
        if (report) {
            navigator.clipboard.writeText(report)
                .then(() => {
                    alert('Report copied to clipboard!');
                })
                .catch(err => {
                    alert('Failed to copy report: ', err);
                });
        }
    };

    return (
        <div className=" mq1280:w-[850px]">
            <h1 className="text-3xl font-bold mb-6">Overall Report</h1>
            
            <div className="flex space-x-4 mb-6">
            <button
                    onClick={fetchReport}
                    disabled={isLoading}
                    className={`flex items-center justify-center px-4 py-2 rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? (
                        <>
                            <FiLoader className="animate-spin mr-2" />
                            Generating... {progress.toFixed(1)}%
                        </>
                    ) : (
                        'Generate Report'
                    )}
                </button>

                {report && (
                    <button
                        onClick={toggleVisibility}
                        className="flex items-center justify-center px-4 py-2 rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                    >
                        {isVisible ? (
                            <>
                                <FiChevronUp className="mr-2" />
                                Collapse Report
                            </>
                        ) : (
                            <>
                                <FiChevronDown className="mr-2" />
                                Expand Report
                            </>
                        )}
                    </button>
                )}
            </div>

            {error && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
                    <div className="flex items-center">
                        <FiAlertCircle className="mr-2" />
                        <p className="font-bold">Error</p>
                    </div>
                    <p>{error}</p>
                </div>
            )}

            {isVisible && report && (
                <div className="bg-gray-100 p-4 rounded-md overflow-x-auto prose prose-sm max-w-none">
                    <button
                        onClick={copyToClipboard}
                        className="flex items-center justify-center px-4 py-2 mb-4 rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        Copy Report
                    </button>
                    <ReactMarkdown
                        components={{
                            h1: ({node, ...props}) => <h1 className="text-2xl font-bold my-4" {...props} />,
                            h2: ({node, ...props}) => <h2 className="text-xl font-bold my-3" {...props} />,
                            h3: ({node, ...props}) => <h3 className="text-lg font-bold my-2" {...props} />,
                            ul: ({node, ...props}) => <ul className="list-disc ml-4 my-2" {...props} />,
                            li: ({node, ...props}) => <li className="my-1" {...props} />,
                            p: ({node, ...props}) => <p className="my-2" {...props} />,
                        }}
                    >
                        {report}
                    </ReactMarkdown>
                </div>
            )}
        </div>
    );
};

export default OverallReport;
