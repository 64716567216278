import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useAuth } from "../../AuthContext"; // Import the useAuth hook

const Paperwork = ({ personId, section_name }) => {
  const { username } = useAuth(); // Get the username from the auth context
  const [hasTranscriptions, setHasTranscriptions] = useState(false);
  const [paperwork, setPaperwork] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true); // State to track collapsed/expanded status
  const reportRef = useRef(null);

  const logoMarkdown = "![Logo](/logo.png)\n\n";

  useEffect(() => {
    if (username) {
      // Fetch interviews to check if transcriptions are available
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/people/${personId}/interviews/${section_name}`,
          {
            params: {
              user_id: username, // Pass the username as user_id
            },
          }
        )
        .then((response) => {
          const interviews = response.data.interviews;
          const transcriptionsExist = interviews.some(
            (interview) =>
              interview.transcription && interview.transcription.trim() !== ""
          );
          setHasTranscriptions(transcriptionsExist);
        })
        .catch((error) => {
          console.error("There was an error fetching the interviews!", error);
        });

      // Fetch the existing paperwork (if any)
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/people/${personId}/paperwork/${section_name}`,
          {
            params: {
              user_id: username, // Pass the username as user_id
            },
          }
        )
        .then((response) => {
          setPaperwork(response.data.paperwork);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the paperwork!", error);
          setIsLoading(false);
        });
    }
  }, [personId, refreshKey, username]);

  const handleGenerateReport = () => {
    if (!hasTranscriptions) return;
    setIsGenerating(true);

    // Start report generation
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/people/${personId}/generate_report/${section_name}`,
        null,
        { params: { user_id: username } }
      )
      .then(() => {
        // Start polling for status
        const checkStatus = () => {
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}/people/${personId}/generate_report/${section_name}/status`,
              { params: { user_id: username } }
            )
            .then((response) => {
              if (response.data.status === "completed") {
                setPaperwork(logoMarkdown + response.data.paperwork);
                setIsGenerating(false);
                clearInterval(statusCheck);
              } else if (response.data.status === "error") {
                console.error("Error generating report:", response.data.error);
                setIsGenerating(false);
                clearInterval(statusCheck);
              }
            })
            .catch((error) => {
              console.error("Error checking status:", error);
              setIsGenerating(false);
              clearInterval(statusCheck);
            });
        };

        const statusCheck = setInterval(checkStatus, 3000);
      })
      .catch((error) => {
        console.error("Error starting report generation:", error);
        setIsGenerating(false);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(paperwork).then(
      () => {
        // alert("Report copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const handleSaveAsPDF = () => {
    const input = reportRef.current;
    const padding = 100; // Increase padding to 100 pixels

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width + 2 * padding;
      const pdfHeight = canvas.height + 2 * padding;
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
        format: [pdfWidth, pdfHeight],
      });

      pdf.addImage(
        imgData,
        "PNG",
        padding,
        padding,
        canvas.width,
        canvas.height
      );
      pdf.save("report.pdf");
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="paperwork p-4 w-full">
      <h3 className="text-xl font-bold mb-4">
        <button onClick={toggleCollapse} className="focus:outline-none">
          {isCollapsed ? "Show Report" : "Hide Report"}
        </button>
      </h3>

      {!isCollapsed && paperwork && (
        <div>
          <div
            ref={reportRef}
            id="report-content"
            className="markdown-body w-full p-2 border border-gray-300 rounded mb-4"
          >
            <ReactMarkdown>{logoMarkdown + paperwork}</ReactMarkdown>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleGenerateReport}
              disabled={!hasTranscriptions || isGenerating}
              className={`font-bold py-2 px-4 rounded ${
                hasTranscriptions && !isGenerating
                  ? "bg-blue-500 hover:bg-blue-700 text-white"
                  : "bg-gray-300 text-gray-700 cursor-not-allowed"
              }`}
            >
              {isGenerating ? "Generating Report..." : "Generate New Report"}
            </button>
            <button
              onClick={handleCopyToClipboard}
              className="font-bold py-2 px-4 rounded bg-green-500 hover:bg-green-700 text-white"
            >
              Copy Report
            </button>
            <button
              onClick={handleSaveAsPDF}
              className="font-bold py-2 px-4 rounded bg-red-500 hover:bg-red-700 text-white"
            >
              Save as PDF
            </button>
          </div>
        </div>
      )}

      {!isCollapsed && !paperwork && (
        <button
          onClick={handleGenerateReport}
          disabled={!hasTranscriptions || isGenerating}
          className={`font-bold py-2 px-4 rounded ${
            hasTranscriptions && !isGenerating
              ? "bg-blue-500 hover:bg-blue-700 text-white"
              : "bg-gray-300 text-gray-700 cursor-not-allowed"
          }`}
        >
          {isGenerating ? "Generating Report..." : "Generate Report"}
        </button>
      )}
    </div>
  );
};

export default Paperwork;
