import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext'; // Import the useAuth hook
import Notification from './Notification';

const Transcription = ({ setIsTranscriptionAvailable }) => {
  const { id, interviewid } = useParams();
  const { username } = useAuth();
  const [transcription, setTranscription] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [notification, setNotification] = useState(null);
  const [hasError, setHasError] = useState(false);

  const checkTranscriptionStatus = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/interviews/${id}/${interviewid}/transcription`, {
        params: {
          user_id: username,
        },
      })
      .then((response) => {
        const fetchedTranscription = response.data.transcription;
        if (fetchedTranscription !== 'Transcription not available') {
          setTranscription(fetchedTranscription);
          setIsTranscriptionAvailable(true);
          setIsGenerating(false);
          setNotification('Transcription is complete!');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error checking transcription status:', error);
        setNotification('Error fetching the transcription status. Please try again.');
        setHasError(true);
      });
  };

  useEffect(() => {
    let interval;
    if (isGenerating) {
      interval = setInterval(checkTranscriptionStatus, 5000);
    }
    return () => clearInterval(interval);
  }, [isGenerating]);

  const handleGenerateTranscription = () => {
    setIsGenerating(true);
    setHasError(false);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/interviews/${id}/${interviewid}/transcription`, null, {
        params: {
          user_id: username,
        },
      })
      .then(() => {
        setNotification('Transcription is being generated...');
      })
      .catch((error) => {
        console.error('Error generating transcription:', error);
        setIsGenerating(false);
        setNotification('Error generating the transcription. Please try again.');
        setHasError(true);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(transcription).then(() => {
      setNotification('Transcription copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy the text to clipboard', error);
    });
  };

  useEffect(() => {
    if (username) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/interviews/${id}/${interviewid}/transcription`, {
          params: {
            user_id: username,
          },
        })
        .then((response) => {
          const fetchedTranscription = response.data.transcription;
          setTranscription(fetchedTranscription);
          setIsLoading(false);
          if (fetchedTranscription !== 'Transcription not available') {
            setIsTranscriptionAvailable(true);
          } else {
            handleGenerateTranscription();
          }
        })
        .catch((error) => {
          console.error('Error fetching transcription:', error);
          setIsLoading(false);
          setNotification('Error fetching the transcription. Please try generating it.');
          setHasError(true);
        });
    }
  }, [id, interviewid, username, setIsTranscriptionAvailable]);

  if (isLoading || isGenerating) {
    return <div className=''>
      
      <h3 className="text-xl font-bold mb-4">Transcription</h3>
      Loading...</div>;
  }

  return (
    <div className="transcription p-4  mq1280:w-[750px]">
      <h3 className="text-xl font-bold mb-4">Transcription</h3>
      {(transcription === 'Transcription not available' || hasError) ? (
        <button 
          onClick={handleGenerateTranscription} 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Generate Transcription
        </button>
      ) : (
        <div>
          <textarea
            value={transcription}
            readOnly
            className="w-full h-64 p-2 border border-gray-300 rounded mb-4"
          />
          <button 
            onClick={handleCopyToClipboard} 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Copy to Clipboard
          </button>
        </div>
      )}
      {notification && (
        <Notification message={notification} onClose={() => setNotification(null)} />
      )}
    </div>
  );
};

export default Transcription;
